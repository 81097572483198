import "./header.scss";
import * as dompack from "dompack";

function initTopSearch( node )
{
  let inpnode = node.parentNode.querySelector("input");

  node.addEventListener("submit", ev => {
    if( !document.documentElement.classList.contains("topsearch--active") && !document.documentElement.classList.contains("mobilemenu-beforeshow") )
    {//if not active and not in mobile menu, enlarge input area
      ev.preventDefault();
      document.documentElement.classList.add("topsearch--active");
      inpnode.focus();
    }
    else if( inpnode.value == "" )
      ev.preventDefault();

  });

  node.querySelector("input").addEventListener("keydown", ev => {
    if( ev.keyCode == 27 && !document.documentElement.classList.contains("mobilemenu-beforeshow")) //Esc
    {
      document.documentElement.classList.remove("topsearch--active");
      inpnode.blur();
    }
  });

  node.querySelector(".close").addEventListener("click", ev => {
    if( document.documentElement.classList.contains("topsearch--active") && !document.documentElement.classList.contains("mobilemenu-beforeshow") )
    {//if active and not in mobile menu, enlarge input area
      document.documentElement.classList.remove("topsearch--active");
      inpnode.blur();
    }
  });
}

dompack.register("#topsearchform", node => initTopSearch( node ) );
