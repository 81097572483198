import "./sidenav.scss";
import * as dompack from "dompack";
import * as whintegration from '@mod-system/js/wh/integration';

class cSideNavPanel
{
  constructor( node )
  {
    this.node = node;

    console.log('go');

    //get node to move
    this.mainnode = document.querySelector("main");
    this.pathnode = document.getElementById("pathnav");
    this.scrollcontainernode = this.node.querySelector(".scrollcontainer");

    //Scroll selected item into view
    let focusnode = this.node.querySelector("li[data-id='" + whintegration.config.obj.id + "']");
    if( !focusnode )//try parent
      focusnode = this.node.querySelector("li[data-id='" + whintegration.config.obj.parent + "']");
    if( focusnode )
    {
      if( document.location.hash )
      {
        let deeplinknode = focusnode.querySelector("a[href$='" + document.location.hash + "']");
        if( deeplinknode )
        {
          focusnode = deeplinknode.parentNode; //LI
          focusnode.classList.add("active");
        }
      }
      let focuspos = focusnode.getBoundingClientRect();
      let containerpos = this.scrollcontainernode.getBoundingClientRect();
      this.scrollcontainernode.scrollTop = ( focuspos.top - (containerpos.top + 100) );
    }

    this.duration = 300;//ms
    this.distance = 325;//px

    this.node.style.transition = "left " + this.duration + "ms";

    this.mainnode.style.transition = "left " + this.duration + "ms";
    if( this.pathnode )
      this.pathnode.style.transition = "left " + this.duration + "ms";

    window.addEventListener("resize", ev => this.onResize(ev) );

    this.btnnode = dompack.create("div", { className : "sidenavtoggle fa fa-list"});
    this.btnnode.addEventListener("click", ev => this.toggleSideNav() );
    window.addEventListener("load", ev => this.onResize(ev));

    for( let node of this.node.querySelectorAll(".closebtn") )
      node.addEventListener("click", ev => this.closePanel() );

    document.body.appendChild( this.btnnode );

    this.closefn = this.closePanel.bind(this);

    this.onResize();
  }

  onResize(ev)
  {
    this.node.style.display = "";
    this.ismobilemode = !this.node.clientWidth;

    this.checkScrollSize();

    if( this.visible )
      this.closePanel( ev );

/*
    this.mainnode.style.minHeight = "";
    this.mainnode.style.paddingBottom = "";
    let minh = (this.node.clientHeight - this.pathnode.clientHeight);
    if( this.mainnode.clientHeight < minh )
    {
      this.mainnode.style.paddingBottom = "0";
      this.mainnode.style.minHeight = minh + "px";
    }
*/

    if( this.ismobilemode )
      this.btnnode.classList.add("visible");
    else
      this.btnnode.classList.remove("visible");
  }

  checkScrollSize()
  {
    let scrollh = 0;
    for( let i = 0; i < this.scrollcontainernode.children.length; ++i )
      scrollh += this.scrollcontainernode.children[i].clientHeight;
    if( scrollh > this.scrollcontainernode.clientHeight )
      this.node.classList.add("scroll");
    else
      this.node.classList.remove("scroll");
  }

  closePanel( ev )
  {
    if( ev )
    {
      if( ev.type == "keydown" && ev.keyCode != 27) //ESC
        return;
      else if( ev.type == "click" )
      {
        if( dompack.closest(ev.target, "#sidenav") || ev.target.classList.contains("sidenavtoggle") )
          return;
      }
    }

    this.visible = false;
    document.documentElement.classList.remove("sidenav--active");

    this.mainnode.style.left = "";
    if( this.pathnode )
      this.pathnode.style.left = "";
    this.node.style.left = "";

    this.btnnode.classList.add("visible");//show btn

    //cleanup events
    document.body.removeEventListener("keydown", this.closefn );
    document.body.removeEventListener("click", this.closefn );

    this.closetimer = setTimeout(function(){
      this.node.style.display = "";
    }.bind(this), this.duration);
  }

  showPanel()
  {
    clearTimeout(this.closetimer);

    if( !this.ismobilemode )
      return;

    document.documentElement.classList.add("sidenav--active");

    this.visible = true;

    //calculate how much we need to shift the mainnode
    this.spaceleft = this.mainnode.getBoundingClientRect().left;
    let translatex = this.distance - this.spaceleft;

    if( translatex < 0 )
      translatex = 0;
    else if( translatex > this.distance )
      translatex = this.distance;

    this.node.style.display = "block";
    this.node.clientWidth;//force css update

    this.node.style.left = "0px";
    this.mainnode.style.left = translatex + "px";
    if( this.pathnode )
      this.pathnode.style.left = translatex + "px";

    this.btnnode.classList.remove("visible");

    this.checkScrollSize();

    document.body.addEventListener("keydown", this.closefn );
    document.body.addEventListener("click", this.closefn );
  }

  toggleSideNav()
  {
    if( this.visible )
      this.closePanel();
    else
      this.showPanel();
  }
}

dompack.register("#sidenav", node => {
  // if( document.documentElement.classList.contains("manual") )
    new cSideNavPanel( node );
});
